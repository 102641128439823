import React from 'react';
import Layout from '../components/Layout';
import {Link} from 'gatsby';

const Service_one = ({location}) => {
    const {state = {}} = location;
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{
                            backgroundImage: `url(../images/products/banner/1.jpg)`,
                        }}
                    >
                        <h1>Trituradora de fardos</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Descripción</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                Hay dos tipos (Desempacadora horizontal y vertical) de trituradoras de balas
                                desarrolladas por Zion Tech. Su capacidad de entrada alcanza hasta 10000 kg / h con
                                respecto a las dimensiones y condiciones de materiales de las balas de botellas de PET.
                                El eje de la trituradora de balas adopta acero al carbono y las paletas están fabricados
                                en acero aleado, son desmontables y fáciles de mantener a diario.
                            </p>
                            <p style={{color: '#000'}}>
                                Se encuentran disponibles 3 tipos especializados de rompe balas que incluyen dos ejes,
                                tres ejes y cuatro ejes para diferentes diseños de proceso. La velocidad del
                                transportador de alimentación está controlada por el inversor, es mejor evitar la
                                obstrucción del material. El rompedor de balas con tres ejes es particularmente fácil
                                para desarmar pacas de botellas de alta densidad. El rompedor de pacas de Zion Tech es
                                fácil de operar, funciona con bajo consumo de energía y menos mano de obra
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Caracteristicas</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                El rompedor de balas se aplica a la industria del reciclaje de botellas de PET para
                                romper los fardos de botellas de PET. Normalmente, se cortan los cables de la bala de
                                botellas de PET con mano de obra en el primer paso, luego los fardos de botellas de PET
                                se transportarán a los ejes del rompedor de balas a través de una cinta transportadora o
                                un transportador de cadena . Las balas de botellas de PET se romperán en botellas
                                sueltas bajo la función de potentes paletas rodantes. El descableado se puede equipar en
                                un rompe balas en lugar de una operación manual.
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Capacidad de entrada</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                1500-10000 kg/h
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Service_one;
